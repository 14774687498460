<template>
  <v-dialog
    value="true"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    overlay-opacity="0.95"
    :overlay-color="template.colors.primary"
    :style="`color: ${template.colors.inline_primary_text};`"
    :elevation="0"
    color="rgba(0,0,0,0)"
    style="
      padding-top: env(safe-area-inset-top);
      background-color: rgba(0, 0, 0, 0);
    "
  >
    <v-card style="min-height: 40vh;">
      <v-img height="250" position="center top" src="/img/v2.png"></v-img>

      <v-card-title>NEUE FUNKTIONEN VERFÜGBAR!</v-card-title>

      <v-card-text>
        <div class="text-justify">
          Mit dem heutigen Update auf Version 2.0 gibt es einige Neuerungen: Da
          in den nächsten Wochen noch weitere Funktionen ergänzt werden, haben
          wir die Benutzeroberfläche angepasst, damit Du nicht den Überblick
          verlierst. Die Fußnavigation ist verschwunden und alle Menu-Punkte
          wurden in einer Navigation zusammengefasst, die über das Menu-Icon
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          aufgerufen werden kann.
          <br />
          <br />
          Zudem haben wir ein übersichtliches Dashboard hinzugefügt auf dem Du
          sofort Deine nächsten Veranstaltungen findest.
          <br />
          <br />
          Eine weitere Neuerung: Vorstände können in der Vereinsverwaltung nun
          mehrere Sportanlagen mit unterschiedlichen QR-Codes einrichten und
          Veranstaltungen können den entsprechenden Sportanlagen zugewiesen
          werden.
          <br />
          <br />
          <b>Was ist noch neu?</b>
          <br />
          Das kannst Du in den
          <v-btn text small color="white" href="/releases" target="_blank">
            Release Notes
          </v-btn>
          erfahren.
        </div>
      </v-card-text>

      <v-card-actions class="text-center">
        <v-btn
          color="white"
          class="mx-auto"
          light
          :block="$vuetify.breakpoint.smAndDown"
          x-large
          @click="closing()"
        >
          OKAY!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Update-Notify',
  data() {
    return {
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.loading = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.uid)
        .update({
          notified: 'v2',
        })
        .then(() => {
          this.$emit('closing', true)
        })
        .catch((error) => {
          this.$emit('closing', true)
        })
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
  },
}
</script>
