<template>
  <div>
    <v-row justify="center" class="pt-2">
      <v-col align-self="center">
        <h2>
          <v-icon class="mr-1">
            mdi-calendar
          </v-icon>
          Veranstaltungen
        </h2>
        <v-btn
          text
          v-if="user.data.rolle == 'Vorstand'"
          class="px-0"
          @click="change_filter = true"
        >
          {{ filter }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
        <v-dialog
          :fullscreen="$vuetify.breakpoint.smAndDown"
          style="padding-top: env(safe-area-inset-top);"
          v-model="change_filter"
          :content-class="
            $vuetify.breakpoint.smAndDown ? undefined : 'rounded-xl'
          "
        >
          <v-card
            :rounded="$vuetify.breakpoint.smAndDown ? undefined : 'xl'"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'width: 100vw; overflow-x: hidden;'
                : ''
            "
          >
            <v-toolbar
              :color="template.colors.primary"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'position: fixed; width: 100vw; z-index: 1000; padding-top: env(safe-area-inset-top); height: 80px + env(safe-area-inset-top);'
                  : ''
              "
            >
              <v-toolbar-title>Veranstaltungen filtern</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="change_filter = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row
              justify="center"
              class="pa-4"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'padding-top: env(safe-area-inset-top); z-index: 500; margin-top: calc(80px + env(safe-area-inset-top));'
                  : ''
              "
            >
              <v-col cols="12" md="5">
                <v-card
                  :color="template.colors.blocks"
                  :light="!template.isDark(template.colors.blocks)"
                  rounded="xl"
                  flat
                  height="100%"
                  class="pa-4"
                >
                  <h4>Sportstätte</h4>
                  <v-divider></v-divider>
                  <v-radio-group v-model="filter_sportstaetten">
                    <v-radio
                      label="Alle Sportstätten"
                      value="Alle Sportstätten"
                    ></v-radio>
                    <v-radio
                      v-for="platz in user.data.sportstaetten"
                      :key="platz.name"
                      :label="`${platz.name}`"
                      :value="platz.name"
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="5"
                v-if="user.data.rolle == 'Vorstand' && user.data.abteilungen"
              >
                <v-card
                  :color="template.colors.blocks"
                  :light="!template.isDark(template.colors.blocks)"
                  rounded="xl"
                  flat
                  height="100%"
                  class="pa-4"
                >
                  <h4>Abteilung</h4>
                  <v-divider></v-divider>
                  <v-radio-group v-model="filter_abteilungen">
                    <v-radio
                      label="Alle Abteilungen"
                      value="Alle Abteilungen"
                    ></v-radio>
                    <v-radio
                      v-for="abt in user.data.abteilungen"
                      :key="abt.name"
                      :label="`${abt.name}`"
                      :value="abt.name"
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>
              <v-col cols="12" md="5">
                <v-card
                  :color="template.colors.blocks"
                  :light="!template.isDark(template.colors.blocks)"
                  rounded="xl"
                  flat
                  height="100%"
                  class="pa-4"
                >
                  <h4>Team</h4>
                  <v-divider></v-divider>
                  <v-radio-group v-model="filter_teams">
                    <v-radio label="Alle Teams" value="Alle Teams"></v-radio>
                    <v-radio
                      v-for="t in teams"
                      :key="t.name"
                      :label="`${t.name}`"
                      :value="t.name"
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>
              <v-col cols="12" md="5">
                <v-card
                  :color="template.colors.blocks"
                  :light="!template.isDark(template.colors.blocks)"
                  rounded="xl"
                  flat
                  height="100%"
                  class="pa-4"
                >
                  <h4>Art</h4>
                  <v-divider></v-divider>
                  <v-radio-group v-model="filter_arten">
                    <v-radio label="Alle Arten" value="Alle Arten"></v-radio>
                    <v-radio
                      v-for="art in template.var.arten"
                      :key="art"
                      :label="`${art}`"
                      :value="art"
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>
              <v-col cols="12"></v-col>
              <v-col cols="12" md="8">
                <v-btn
                  rounded="xl"
                  :color="template.colors.primary"
                  large
                  block
                  @click="
                    filter_veranstaltungen()
                    change_filter = false
                  "
                >
                  <v-icon class="mr-2">mdi-filter</v-icon>
                  Filtern
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-menu rounded="large" bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="template.colors.primary"
              fab
              small
              rounded
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="newveranstaltung = true">
              <v-list-item-avatar>
                <v-icon>mdi-calendar-plus</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Veranstaltung erstellen</v-list-item-title>
            </v-list-item>
            <v-list-item @click="multidelete = true">
              <v-list-item-avatar>
                <v-icon>mdi-calendar-remove</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Veranstaltungen entfernen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="px-2">
        <v-tabs
          background-color="rgba(0,0,0,0)"
          v-model="tabs"
          dark
          hide-slider
          active-class="text-subtitle-1 font-weight-bold"
          color="white"
          fixed-tabs
          icons-and-text
        >
          <v-tab>
            Geplant
            <v-icon>mdi-update</v-icon>
          </v-tab>
          <v-tab>
            Heute
            <v-icon>mdi-clock-outline</v-icon>
          </v-tab>
          <v-tab>
            Vergangen
            <v-icon>mdi-history</v-icon>
          </v-tab>
        </v-tabs>
        <CreateVeranstaltung
          v-if="newveranstaltung"
          v-on:closing="newveranstaltung = false"
        />
        <MultipleDelete v-if="multidelete" v-on:closing="multidelete = false" />
      </v-col>
    </v-row>
    <v-row
      justify="center"
      style="max-height: 70vh; padding-bottom: 5vh; overflow-y: scroll;"
    >
      <v-tabs-items
        light
        v-model="tabs"
        continuous
        :style="`width: 100%; overflow-x: hidden; background-color: rgba(0,0,0,0); padding-bottom: 10vh;`"
      >
        <v-tab-item color="rgba(0,0,0,0)">
          <v-row justify="center">
            <v-col
              cols="10"
              class="text-center pa-4 my-4"
              v-if="future_dates.length == 0"
            >
              <h3 class="font-weight-light">
                Du hast derzeit keine geplanten Veranstaltungen.
                <br />
                <v-btn
                  :color="template.colors.primary"
                  class="mt-3"
                  rounded
                  :light="!template.isDark(template.colors.primary)"
                  :dark="template.isDark(template.colors.primary)"
                  @click="newveranstaltung = true"
                >
                  <v-icon class="mr-2">mdi-calendar-plus</v-icon>
                  Veranstaltung erstellen
                </v-btn>
              </h3>
            </v-col>
            <v-col
              cols="11"
              class="text-left"
              align-self="center"
              v-if="future_dates.length > 0"
            >
              <v-row
                class="mb-2"
                justify="center"
                v-for="elem in future_dates"
                :key="elem.datum"
              >
                <v-col cols="12" class="mb-0 pb-0">
                  <v-row>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      <span
                        class="text-uppercase font-weight-bold mx-2"
                        :style="`color: ${template.colors.inline_primary_text};`"
                      >
                        {{ parseDate(elem.datum) }}
                      </span>
                    </v-col>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-for="v in elem.veranstaltungen" :key="v.id">
                  <cardveranstaltung
                    :abgesagt="v.abgesagt"
                    :art="v.art"
                    :gastmannschaft="v.gastmannschaft"
                    :id="v.id"
                    :mannschaft="v.mannschaft"
                    :name="v.name"
                    :sportstaette="v.sportstaette"
                    :beginnuhrzeit="v.beginn.uhrzeit"
                    :beginndatum="v.beginn.datum"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row justify="center">
            <v-col
              cols="10"
              class="text-center pa-4 my-4"
              v-if="todays_dates.length == 0"
            >
              <h3 class="font-weight-light">
                Du hast keine heutigen Veranstaltungen angelegt.
                <br />
                <v-btn
                  class="mt-3"
                  rounded
                  :color="template.colors.primary"
                  :light="!template.isDark(template.colors.primary)"
                  :dark="template.isDark(template.colors.primary)"
                  @click="newveranstaltung = true"
                >
                  <v-icon class="mr-2">mdi-calendar-plus</v-icon>
                  Veranstaltung erstellen
                </v-btn>
              </h3>
            </v-col>
            <v-col
              cols="11"
              class="text-left"
              align-self="center"
              v-if="todays_dates.length > 0"
            >
              <v-row
                justify="center"
                class="mb-2"
                v-for="elem in todaysdates"
                :key="elem.datum"
              >
                <v-col cols="12" class="mb-0 pb-0">
                  <v-row>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      <span
                        class="text-uppercase font-weight-bold mx-2"
                        :style="`color: ${template.colors.inline_primary_text};`"
                      >
                        {{ parseDate(elem.datum) }}
                      </span>
                    </v-col>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-for="v in elem.veranstaltungen" :key="v.id">
                  <cardveranstaltung
                    :abgesagt="v.abgesagt"
                    :art="v.art"
                    :gastmannschaft="v.gastmannschaft"
                    :gastgeberverein="v.gastgeberverein"
                    :id="v.id"
                    :mannschaft="v.mannschaft"
                    :sportstaette="v.sportstaette"
                    :name="v.name"
                    :beginnuhrzeit="
                      v.beginn.datum == v.ende.datum
                        ? v.beginn.uhrzeit
                        : v.beginn.datum == timer.datum
                        ? v.beginn.uhrzeit
                        : v.beginn.datum < timer.datum &&
                          v.ende.datum > timer.datum
                        ? 'GANZTÄGIG'
                        : 'bis ' + v.ende.uhrzeit
                    "
                    :beginndatum="
                      v.beginn.datum == v.ende.datum
                        ? v.beginn.datum
                        : v.beginn.datum <= timer.datum &&
                          v.ende.datum >= timer.datum
                        ? timer.datum
                        : v.ende.datum
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row justify="center">
            <v-col
              cols="10"
              class="text-center pa-4 my-4"
              v-if="past_dates.length == 0"
            >
              <h3 class="font-weight-light">
                Du hast derzeit keine vergangenen Veranstaltungen oder sie
                liegen schon zu weit in der Vergangenheit (älter als 4 Wochen).
                <br />
                <v-btn
                  class="mt-3"
                  rounded
                  :color="template.colors.primary"
                  :light="!template.isDark(template.colors.primary)"
                  :dark="template.isDark(template.colors.primary)"
                  @click="newveranstaltung = true"
                >
                  <v-icon class="mr-2">mdi-calendar-plus</v-icon>
                  Veranstaltung erstellen
                </v-btn>
              </h3>
            </v-col>
            <v-col
              cols="11"
              class="text-left"
              align-self="center"
              v-if="past_dates.length > 0"
            >
              <v-row
                class="mb-2"
                justify="center"
                v-for="elem in past_dates"
                :key="elem.datum"
              >
                <v-col cols="12" class="mb-0 pb-0">
                  <v-row>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      <span
                        class="text-uppercase font-weight-bold mx-2"
                        :style="`color: ${template.colors.inline_primary_text};`"
                      >
                        {{ parseDate(elem.datum) }}
                      </span>
                    </v-col>
                    <v-col align-self="center">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-for="v in elem.veranstaltungen" :key="v.id">
                  <cardveranstaltung
                    :abgesagt="v.abgesagt"
                    :art="v.art"
                    :gastmannschaft="v.gastmannschaft"
                    :id="v.id"
                    :mannschaft="v.mannschaft"
                    :sportstaette="v.sportstaette"
                    :name="v.name"
                    :beginnuhrzeit="v.beginn.uhrzeit"
                    :beginndatum="v.beginn.datum"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <UpdateNotify v-if="notify == 'update'" v-on:closing="notify = false" />
  </div>
</template>

<script>
import cardveranstaltung from '../../../elements/cards/card-veranstaltung'
import CreateVeranstaltung from './create'
import UpdateNotify from '../../benachrichtigungen/update_available'
import MultipleDelete from './multiple_delete'
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes'

export default {
  name: 'Start',
  data() {
    return {
      newveranstaltung: false,
      veranstaltungen: [],
      veranst_date: [],
      future_dates: [],
      past_dates: [],
      todays_dates: [],
      date: '',
      tabs: 1,
      add_to_home: false,
      multidelete: false,
      notify: false,
      change_filter: false,
      filter: 'Alle Veranstaltungen',
      filter_teams: 'Alle Teams',
      filter_abteilungen: 'Alle Abteilungen',
      filter_sportstaetten: 'Alle Sportstätten',
      filter_arten: 'Alle Arten',
      filtered_veranstaltungen: [],
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Wettkampf',
        'Versammlung',
      ],
      teams: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      saved_filter: 'filter',
      timer: 'timer',
    }),
    todaysdates() {
      if (this.todays_dates.length > 0) {
        var arr = this.todays_dates
        var output = []
        var output1 = []

        arr[0].veranstaltungen.forEach((event) => {
          if (
            event.beginn.datum < this.timer.datum &&
            event.ende.datum > this.timer.datum
          ) {
            output.push(event)
          }
        })

        arr[0].veranstaltungen.forEach((event) => {
          if (
            event.beginn.datum == this.timer.datum &&
            !output.includes(event)
          ) {
            output.push(event)
          }
        })
        arr[0].veranstaltungen.forEach((event) => {
          if (event.ende.datum == this.timer.datum && !output.includes(event)) {
            output1.push(event)
          }
        })
        output1.sort((a, b) => {
          if (a.ende.uhrzeit > b.ende.uhrzeit) {
            return 1
          } else {
            return -1
          }
        })
        output = output.concat(output1)

        return [
          {
            datum: arr[0].datum,
            veranstaltungen: output,
          },
        ]
      } else {
        return []
      }
    },
  },
  mounted() {
    if (this.user) {
      if (this.user.data) {
        if (this.user.data.rolle) {
          if (
            this.user.data.rolle == 'Zuschauer' ||
            this.user.data.rolle == 'Schiedsrichter' ||
            (this.user.data.verband != this.template.var.verband &&
              !router.currentRoute.fullPath
                .toString()
                .includes('/veranstaltung/'))
          ) {
            if (this.user.fullfill) {
              router.push('/profil-vervollstaendigen')
            } else {
              router.push('/user/check-ins')
            }
          }
        }
      } else {
        router.push('/login')
      }
    } else {
      router.push('/login')
    }
    store.dispatch('setTemplateBackgroundGradient', {
      start: '10%',
      end: '50%',
    })
    this.filter_teams = this.saved_filter.veranstaltungen.team
    this.filter_abteilungen = this.saved_filter.veranstaltungen.abteilung
    this.filter_sportstaetten = this.saved_filter.veranstaltungen.sportstaette
    this.filter_arten = this.saved_filter.veranstaltungen.art
    /*if (!this.user.data.notified && this.user.data.rolle != 'Zuschauer') {
      this.notify = 'update'
    }*/
    var d = new Date()
    d.setDate(d.getDate() - 28)
    var todate =
      d.getFullYear() +
      '-' +
      (parseInt(d.getMonth()) + 1 < 10
        ? '0' + parseInt(d.getMonth() + 1)
        : parseInt(d.getMonth() + 1)) +
      '-' +
      (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
    if (this.user.data.rolle == 'Vorstand') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .where('beginn.datum', '>=', todate)
        .orderBy('beginn.datum', 'desc')
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot(
          (querySnap) => {
            this.veranstaltungen = []
            querySnap.forEach((doc) => {
              this.veranstaltungen.push(doc.data())
              var index = this.veranstaltungen.length - 1
              this.veranstaltungen[index].id = doc.id
            })
            this.filter_veranstaltungen()
          },
          (error) => {
            console.log(error)
          },
        )

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .get()
        .then((teamsnap) => {
          teamsnap.forEach((team) => {
            this.teams.push(team.data())
          })
        })
    } else if (this.user.data.rolle == 'Trainer') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .orderBy('beginn.datum', 'desc')
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot(
          (querySnap) => {
            this.veranstaltungen = []
            querySnap.forEach((doc) => {
              if (
                this.user.data.team.includes(doc.data().mannschaft) ||
                (['Wettkampf', 'Versammlung'].includes(doc.data().art) &&
                  !doc.data().mannschaft)
              ) {
                if (doc.data().beginn.datum >= todate) {
                  this.veranstaltungen.push(doc.data())
                  var index = this.veranstaltungen.length - 1
                  this.veranstaltungen[index].id = doc.id
                }
              }
            })
            this.filter_veranstaltungen()
          },
          (error) => {
            console.log(error)
          },
        )
      this.user.data.team.forEach((team) => {
        this.teams.push({ name: team })
      })
    }
  },
  components: {
    CreateVeranstaltung,
    MultipleDelete,
    UpdateNotify,
    cardveranstaltung,
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ]
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    filter_veranstaltungen() {
      this.filtered_veranstaltungen = []
      this.veranstaltungen.forEach((v) => {
        var check = true
        if (this.filter_sportstaetten != 'Alle Sportstätten') {
          if (v.sportstaette != this.filter_sportstaetten) {
            check = false
          }
        }

        if (this.filter_abteilungen != 'Alle Abteilungen') {
          this.teams.forEach((t) => {
            if (
              t.abteilung != this.filter_abteilungen &&
              v.mannschaft == t.name
            ) {
              check = false
            }
          })
        }

        if (this.filter_teams != 'Alle Teams') {
          if (this.filter_teams != v.mannschaft) {
            check = false
          }
        }

        if (this.filter_arten != 'Alle Arten') {
          if (this.filter_arten != v.art) {
            check = false
          }
        }

        if (check) {
          this.filtered_veranstaltungen.push(v)
        }
      })

      this.filter = ''
      if (
        this.filter_abteilungen == 'Alle Abteilungen' &&
        this.filter_sportstaetten == 'Alle Sportstätten' &&
        this.filter_teams == 'Alle Teams' &&
        this.filter_arten == 'Alle Arten'
      ) {
        this.filter = 'Alle Veranstaltungen'
      }

      if (this.filter_abteilungen != 'Alle Abteilungen') {
        this.filter += this.filter_abteilungen
      }
      if (this.filter_sportstaetten != 'Alle Sportstätten') {
        if (this.filter != '') {
          this.filter += ' | '
        }
        this.filter += this.filter_sportstaetten
      }
      if (this.filter_teams != 'Alle Teams') {
        if (this.filter != '') {
          this.filter += ' | '
        }
        this.filter += this.filter_teams
      }
      if (this.filter_arten != 'Alle Arten') {
        if (this.filter != '') {
          this.filter += ' | '
        }
        this.filter += this.filter_arten
      }
      store.dispatch('setFilterVeranstaltungen', {
        abteilung: this.filter_abteilungen,
        sportstaette: this.filter_sportstaetten,
        team: this.filter_teams,
        art: this.filter_arten,
      })
      this.sort_veranstaltungen()
    },
    sort_veranstaltungen() {
      var last_date, i
      var index_past = 0
      var index_future = 0
      var index_today = 0
      var datum_obj = new Date()
      this.datum =
        datum_obj.getFullYear() +
        '-' +
        (datum_obj.getMonth() + 1 >= 10
          ? datum_obj.getMonth() + 1
          : '0' + (datum_obj.getMonth() + 1)) +
        '-' +
        (datum_obj.getDate() >= 10
          ? datum_obj.getDate()
          : '0' + datum_obj.getDate())
      this.future_dates = []
      this.todays_dates = [
        {
          datum: this.datum,
          veranstaltungen: [],
        },
      ]
      this.past_dates = []
      for (i = 0; i < this.filtered_veranstaltungen.length; i++) {
        if (
          this.datum == this.filtered_veranstaltungen[i].beginn.datum ||
          (this.filtered_veranstaltungen[i].beginn.datum < this.datum &&
            this.filtered_veranstaltungen[i].ende.datum >= this.datum)
        ) {
          this.todays_dates[0].veranstaltungen.push(
            this.filtered_veranstaltungen[i],
          )
        } else if (this.datum > this.filtered_veranstaltungen[i].beginn.datum) {
          if (last_date != this.filtered_veranstaltungen[i].beginn.datum) {
            last_date = this.filtered_veranstaltungen[i].beginn.datum
            this.past_dates.push({
              datum: last_date,
              veranstaltungen: [],
            })
            index_past++
            this.past_dates[index_past - 1].veranstaltungen.push(
              this.filtered_veranstaltungen[i],
            )
          } else {
            this.past_dates[index_past - 1].veranstaltungen.push(
              this.filtered_veranstaltungen[i],
            )
          }
        } else {
          if (last_date != this.filtered_veranstaltungen[i].beginn.datum) {
            last_date = this.filtered_veranstaltungen[i].beginn.datum
            this.future_dates.unshift({
              datum: last_date,
              veranstaltungen: [],
            })
            index_future++
            this.future_dates[0].veranstaltungen.unshift(
              this.filtered_veranstaltungen[i],
            )
          } else {
            this.future_dates[0].veranstaltungen.unshift(
              this.filtered_veranstaltungen[i],
            )
          }
        }
      }
    },
  },
}
</script>
