<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>
          Mehrere Veranstaltungen löschen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4" v-if="teams.length != 0">
          <v-col cols="10" lg="8" class="px-2">
            <v-select
              outlined
              v-model="v.art"
              :items="arten"
              label="Art der Veranstaltung"
              :rules="[rules.required]"
              multiple
            ></v-select>
            <v-select
              v-if="teamsloaded && v.art != 'Wettkampf'"
              v-model="v.team"
              :items="teams"
              item-text="name"
              item-value="name"
              :rules="[rules.required]"
              outlined
              :label="
                v.art == 'Meisterschaftsspiel' ||
                v.art == 'Freundschaftsspiel' ||
                v.art == 'Pokalspiel'
                  ? 'Heimmannschaft'
                  : 'Mannschaft'
              "
            ></v-select>
            <v-divider class="mb-4"></v-divider>
            <v-dialog
              ref="beginndatum"
              v-model="beginndatum"
              :return-value.sync="v.beginn"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="parse_datum(v.beginn)"
                  label="Von Datum"
                  :rules="[rules.required]"
                  outlined
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="de"
                first-day-of-week="1"
                v-model="v.beginn"
                scrollable
                :min="today"
                :max="v.ende ? v.ende : undefined"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  :color="template.colors.primary"
                  @click="beginndatum = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  :color="template.colors.primary"
                  @click="
                    $refs.beginndatum.save(v.beginn)
                    v.ende = v.beginn
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="bisdatum"
              v-model="bisdatum"
              :return-value.sync="v.ende"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="parse_datum(v.ende)"
                  label="Bis Datum"
                  outlined
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="de"
                first-day-of-week="1"
                v-model="v.ende"
                :min="v.beginn ? v.beginn : today"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  :color="template.colors.primary"
                  @click="bisdatum = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  :color="template.colors.primary"
                  @click="$refs.bisdatum.save(v.ende)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-divider></v-divider>
            <v-checkbox
              v-model="checkbox"
              label="Ich bestätige, dass ich alle Veranstaltungen, die den oben eingegenen Kriterien entsprechen, UNWIDERRUFLICH löschen möchte."
            ></v-checkbox>
            <v-btn
              class="my-4"
              block
              large
              :color="template.colors.primary"
              :disabled="
                !(v.art.length > 0 && checkbox && v.beginn && v.ende && v.team)
              "
              @click="delete_veranstaltungen()"
              :loading="deleting"
            >
              <v-icon class="mr-2">mdi-remove</v-icon>
              Endgültig löschen
            </v-btn>
            <v-btn
              class="my-4"
              block
              large
              outlined
              :color="template.colors.primary"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row
        justify="center"
        class="mt-4"
        style="height: 70vh;"
        v-if="teams.length == 0 && teamsloaded"
      >
        <v-col cols="10" lg="8" align-self="center">
          <h2>
            Es sieht so aus als hättest du noch kein Team angelegt. Bevor du
            eine Veranstaltung erstellen kannst, musst du ein Team erstellen.
          </h2>
        </v-col>
        <v-col cols="10" lg="8" align-self="center">
          <v-btn color="success" large block to="/teams">
            Jetzt Team erstellen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Veranstaltung-erstellen',
  data() {
    return {
      v: {
        art: [],
        team: '',
        beginn: '',
        ende: '',
      },
      checkbox: false,
      teams: [],
      teamsloaded: false,
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      deleting: false,
      bisdatum: false,
      today: '',
      valid: false,
      vondatum: false,
      endeuhrzeit: false,
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Wettkampf',
      ],
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
      },
      counter: 0,
    }
  },
  mounted() {
    if (this.user.data.rolle == 'Vorstand') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .orderBy('name')
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            this.teams.push(doc.data())
            var id = this.teams.length - 1
            this.teams[id].id = doc.id
          })
          this.teamsloaded = true
        })
        .catch((error) => {
          console.log(error)
        })
    } else if (this.user.data.rolle == 'Trainer') {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .where('name', 'in', this.user.data.team)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            this.teams.push(doc.data())
            var id = this.teams.length - 1
            this.teams[id].id = doc.id
          })
          this.teamsloaded = true
        })
        .catch((error) => {
          console.log(error)
        })
    }
    var datum = new Date()
    datum.setDate(datum.getDate() + 1)
    this.today =
      datum.getFullYear() +
      '-' +
      this.addNull(datum.getMonth() + 1) +
      '-' +
      this.addNull(datum.getDate())
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    addNull(val) {
      if (val >= 10) {
        return val
      } else {
        return '0' + val
      }
    },
    delete_veranstaltungen() {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .where('beginn.datum', '>=', this.v.beginn)
        .where('beginn.datum', '<=', this.v.ende)
        .where('mannschaft', '==', this.v.team)
        .where('art', 'in', this.v.art)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Veranstaltung')
              .doc(doc.id)
              .delete()
          })
          this.deleting = false
          this.closing()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
